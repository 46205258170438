@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.container{
    padding: 20px 10px 15px 10px;
    max-width: 760px;
}
.MuiAutocomplete-root{
    width: 100% !important;
}
.MuiTextField-root, 
.MuiButton-root{
    width: 100%;
}
.marginBottom10{
    margin-bottom: 10px;
}
.marginBottom20{
    margin-bottom: 20px;
}
.marginBottom17{
    margin-bottom: 17px;
}
.marginBottom5{
    margin-bottom: 5px;
}

