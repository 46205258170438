body {
  margin: 0;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  /* overscroll-behavior-y: contain; */
}

/* globalStyles.css */
*::-webkit-scrollbar {
  width: 2px;
}

*::-webkit-scrollbar-track {
  background: var(--tg-theme-secondary-bg-color);
}

*::-webkit-scrollbar-thumb {
  background: var(--tg-theme-secondary-bg-color);
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--tg-theme-secondary-bg-color);
}
.back-next-buttons {
  position: fixed;
  bottom: 30px;
  left: 10px;
  right: 10px;
  z-index: 10;
  display: flex;
}
