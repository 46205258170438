.alert-error {
  border: 1px solid;
  border-radius: "2px";
}
.whiteSpaceNormal {
  white-space: normal;
}
.item-34 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: initial;
  height: auto;
  margin-bottom: 1rem;
  font-size: 16px;
  & .MuiChip-label {
    white-space: normal;
  }
}
.comments {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  overflow-y: scroll;
}
.rating-button {
  border: none;
  background-color: #ffd700; /* Gold color */
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rating-button:hover,
.rating-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.star-icon {
  fill: white;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.rating-button:hover .star-icon,
.rating-button:focus .star-icon {
  transform: scale(1.2);
}
